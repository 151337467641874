import StatisticsService from "@/api/serviceStatistics";

const state = () => ({
  browsers: [],
  countries: [],
  os: [],
  sub_accounts: [],
});

const getters = {
  getBrowsers: (state) => {
    return state.browsers;
  },
  getCountries: (state) => {
    return state.countries;
  },
  getOS: (state) => {
    return state.os;
  },
  getSubAccounts: (state) => {
    return state.sub_accounts;
  },
};

const mutations = {
  setBrowsers(state, payload) {
    if (payload) {
      state.browsers = payload;
    }
  },
  setCountries(state, payload) {
    if (payload) {
      state.countries = payload;

      state.countries.unshift({
        name_ru: "Все страны",
        name_en: "All countries",
        iso: "",
      });
    }
  },
  setOS(state, payload) {
    if (payload) {
      state.os = payload;
    }
  },
  setSubAccounts(state, payload) {
    if (payload) {
      state.sub_accounts = payload;
      state.sub_accounts.unshift({
        id: 0,
        name: "All platforms",
        trash: 0,
      });
    }
  },
};

const actions = {
  handleBrowsers({ commit }) {
    StatisticsService.getBrowsers().then((result) => {
      commit("setBrowsers", result);
    });
  },
  handleCountries({ commit }) {
    StatisticsService.getCountries().then((result) => {
      commit("setCountries", result);
    });
  },
  handleOS({ commit }) {
    StatisticsService.getOs().then((result) => {
      commit("setOS", result);
    });
  },
  handleSubAccounts({ commit }) {
    StatisticsService.getSubAccs().then((result) => {
      commit("setSubAccounts", result);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
